<template>
    <div data-accordion="open">
        <h2 id="accordion-open-heading-2">
            <button type="button" class="flex items-center justify-between w-full px-4 py-2 font-medium text-left text-black !bg-gray-100 border border-gray-200 rounded-t-xl" data-accordion-target="#accordion-open-body-2" aria-expanded="true" aria-controls="accordion-open-body-2">
                <span class="flex items-center">{{ $t("safety.header.safety") }}</span>
                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h2>
        <div id="accordion-open-body-2" class="hidden" aria-labelledby="accordion-open-heading-2">
            <div class="p-5 pb-0 border border-gray-200">
                <div class="mb-2">
                    <AlarmDestinationsSettings @updateConfig="onUpdateControllerId" :refresh="props.refresh" />
                    <DeviceTabBracelet @updateConfig="onUpdateBracelets" :refresh="props.refresh" />
                    <AlarmTriggersEditor @updateConfig="onUpdateAlarmTriggers" :refresh="props.refresh" />
                    <DeviceDailyCheckIn @updateConfig="onUpdateDailyCheckIn" :refresh="props.refresh" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AlarmDestinationsSettings from "@/components/AlarmDestinationsSettings";
import AlarmTriggersEditor from "@/components/AlarmTriggersEditor";
import DeviceDailyCheckIn from "@/components/DeviceDailyCheckIn";
import DeviceTabBracelet from "@/components/DeviceTabBracelet";
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["updateConfig"]);
const props = defineProps(["refresh"])

const onUpdateControllerId = ({ index, value }) => {
    emit("updateConfig", { alarmConfig: { index: index, value: value } });
};

const onUpdateBracelets = (bracelets) => {
    emit("updateConfig", { bracelets: bracelets });
};

const onUpdateAlarmTriggers = (alarmTriggers) => {
    emit("updateConfig", { alarmTriggers: alarmTriggers });
};

const onUpdateDailyCheckIn = (dailyCheckInConfig) => {
    emit("updateConfig", { dailyCheckIn: dailyCheckInConfig });
};


</script>

import i18n from "@/i18n";

const ALARMLEVELS = ["unknown", "not_applicable", "normal", "warning", "minor", "major", "critical"];

const mapStructure = (device) => {
  if (!device) return {};
  return {
    network: {
      cloud_connection: device.alarms.deviceReportedAlarms.network.cloudConnection,
      modem_link: device.alarms.deviceReportedAlarms.network.modemLink,
      modem_connection_quality: device.alarms.deviceReportedAlarms.network.modemConnectionQuality,
      network_cloud_reported: device.alarms.cloudReportedAlarms.network.devicePresence,
    },
    power: {
      power_supply: device.alarms.deviceReportedAlarms.power.powerSupply,
      battery_fault: device.alarms.deviceReportedAlarms.power.batteryFault,
    },
    wifi: {
      wifi_link: device.alarms.deviceReportedAlarms.network.wifiLink,
      wifi_connection_quality: device.alarms.deviceReportedAlarms.network.wifiConnectionQuality,
    },
    accessories: {
      bracelet_presence: device.alarms.deviceReportedAlarms.accessories.braceletPresence,
      bracelet_battery: device.alarms.deviceReportedAlarms.accessories.braceletBattery,
    },
  };
};

/*
 * Return most critical  alert level of 'alert'
 */
const getAlertLevel = (alert, device) => {
  return ALARMLEVELS[getCriticalIndex(alert, device)];
};

const getMostCriticalAlert = (alert, device) => {
  const alarmSummary = mapStructure(device);
  const severity = ALARMLEVELS[getCriticalIndex(alert, device)];
  const severeAlert = Object.keys(alarmSummary[alert]).find((key) => alarmSummary[alert][key] == severity);

  return i18n.global.t("alarms." + severeAlert) + ": " + i18n.global.t("alarms." + severity);
};

const getAllAlerts = (alert, device) => {
  const alarmSummary = mapStructure(device);

  let alerts = "";
  for (const [key, value] of Object.entries(alarmSummary[alert])) {
    alerts +=
      "<p><span class='text-sm font-light'>" +
      i18n.global.t("alarms." + key) +
      "</span>: <span class='font-medium'>" +
      i18n.global.t("alarms." + value) +
      "</span></p>";
  }

  return alerts;
};

const getCriticalIndex = (alert, device) => {
  const alarmSummary = mapStructure(device);

  let highestAlertIndex = 0;

  if (device && alarmSummary && alarmSummary[alert]) {
    for (const networkAlert in alarmSummary[alert]) {
      let findIndex = ALARMLEVELS.findIndex((level) => level == alarmSummary[alert][networkAlert]);
      if (findIndex > highestAlertIndex) highestAlertIndex = findIndex;
    }
  }

  return highestAlertIndex;
};

const hasAlert = (alert, device) => {
  const severity = getAlertLevel(alert, device);
  // unknown, not_applicable, normal is not showing alert.
  if (severity == ALARMLEVELS[0] || severity == ALARMLEVELS[1] || severity == ALARMLEVELS[2]) {
    return false;
  }
  return true;
};

const hasWifiConnection = (device) => {
  if (!device) return false;
  //  TODO hansi rework with new device states
  if (device && device.configV2 && device.configV2.system.wifi.networks) {
    let foundWifi = device.configV2.system.wifi.networks.filter(
      (network) => network.ssid != "" && network.pre_shared_key != ""
    );
    if (foundWifi && foundWifi.length > 0) return true;
  }
  return false;
};

export { ALARMLEVELS, getAlertLevel, hasAlert, getMostCriticalAlert, getAllAlerts, hasWifiConnection };

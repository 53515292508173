<template>
  <div class="relative top-0 z-30 p-4 bg-white lg:sticky">
    <DeviceStateSummary />
  </div>

  <div v-if="loading" class="absolute flex justify-center w-full h-full mt-48">
    <LoadingSpinner></LoadingSpinner>
  </div>

  <div :class="['z-10 flex flex-row flex-wrap-reverse px-4 pt-4 mb-0 sm:mb-10 lg:h-full', loading && 'blur-[1px]']">
    <div class="flex flex-col flex-1 h-full min-w-full pb-10 space-y-4 lg:pr-5 lg:mr-10 lg:overflow-y-scroll lg:pb-64 lg:min-w-0 force-scrollbar">
      <h1 class="mb-2 text-lg font-medium leading-6 text-gray-900">
        {{ $t("configuration.header.config") }}
      </h1>
      <div v-if="!hasVersionSupport()" class="px-4 py-2 overflow-hidden text-gray-800 bg-gray-100 rounded-lg shadow-md overflow h-18">
        <p class="pb-2">{{ t("device.softwareSupport") }}</p>

        <div class="flex items-center gap-3 pt-2 my-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
          </svg>
          <a href='malito:support@caruhome.com' class="underline cursor-pointer ">support@caruhome.com</a>
        </div>

        <div class="flex gap-10">
          <div class="flex items-center gap-3 pt-2 my-auto">
            <span>🇨🇭</span>
            <a href='tel:+41445121377' class="underline cursor-pointer ">+41 44 5121377</a>
          </div>

          <div class="flex items-center gap-3 pt-2 my-auto">
            <span>🇩🇪</span>
            <a href='tel:+49 451 81189063' class="underline cursor-pointer ">+49 451 81189063</a>
          </div>
        </div>
      </div>

      <DeviceSystemSettings v-if="hasVersionSupport()" @updateConfig="onUpdateSystemConfig" :refresh="refresh" />
      <DeviceSafetySettings v-if="hasVersionSupport()" @updateConfig="onUpdateSecurityConfig" :refresh="refresh" />
      <DeviceHealthSettings v-if="hasVersionSupport()" @updateConfig="onUpdateHealthConfig" :refresh="refresh" />
    </div>

    <div class="flex-1 h-full pb-1 lg:overflow-y-scroll lg:pb-64 force-scrollbar">
      <DeviceAuditLog />
    </div>
  </div>

  <div class="bottom-0 z-40 flex flex-row items-center justify-between w-full px-4 py-2 pr-4 bg-white sm:fixed md:pr-72">
    <div class="flex gap-3 w-52">
      <button type="button" class="justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50" @click="cancel">
        {{ $t("common.action.cancel") }}
      </button>
      <button v-if="isEditAllowed" type="submit" :disabled=!enableSave @click="confirmOpen = true" class="justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
        {{ $t("common.action.save") }}
      </button>

      <SimpleAlert @cancel="confirmOpen = false" @approve="save" :open="confirmOpen" :title="$t('alert.applyChangesTitle')" :description="$t('alert.applyChangesDescription')" :action="$t('common.action.apply')" type="info"></SimpleAlert>
      <SimpleAlert @cancel="confirmRedirect = false" @approve="leave" :open="confirmRedirect" :title="$t('alert.leavePage')" :description="$t('alert.leavePageDescription')" :action="$t('common.action.leave')" type="info"></SimpleAlert>
      <SimpleAlert @cancel="confirmLooseConfig = false" @approve="confirmLooseConfigHandler" :open="confirmLooseConfig" :title="$t('alert.leavePage')" :description="$t('alert.leavePageDescription')" :action="$t('common.action.leave')" type="info"></SimpleAlert>
    </div>
    <div>
      <div class="cursor-pointer hover:underline" v-if="isSuperAdmin" @click="openExpertViewHandler">
        {{ $t("configuration.expertMode") }}
      </div>
    </div>
  </div>

  <ExpertView @cancel="cancel(); openExpertView = false" @approve="saveJsonConfig" :open="openExpertView"></ExpertView>
</template>

<script>
import DeviceAuditLog from "@/components/DeviceAuditLog";
import { useStore } from "vuex";
import Store from "@/store";

import {
  batteryStatusColor,
  modemStatusColor,
  overallBraceletStatusColor,
  wifiStatusColor,
  deviceIdentityStatusColor,
  dailyCheckInStatusColor,
} from "@/lib/statusColors";

import DeviceSystemSettings from "@/components/DeviceSystemSettings";
import DeviceSafetySettings from "@/components/DeviceSafetySettings";
import DeviceHealthSettings from "@/components/DeviceHealthSettings";
import DeviceStateSummary from "@/components/DeviceStateSummary";
import ExpertView from "@/components/ExpertView";
import SimpleAlert from "@/components/modals/SimpleAlert";
import LoadingSpinner from "@/components/LoadingSpinner";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { batteryTicks, connectionTicks, signalTicks } from "@/lib/timeseries";
import { dailyCheckInStatusText } from "@/lib/statusTexts";
import { initAccordions } from "flowbite";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave } from "vue-router";

export default {
  name: "DeviceTabGeneral",
  methods: {
    dailyCheckInStatusColor,
    modemStatusColor,
    overallBraceletStatusColor,
    wifiStatusColor,
    batteryStatusColor,
    deviceIdentityStatusColor,
    dailyCheckInStatusText,
  },
  components: {
    DeviceSafetySettings,
    DeviceHealthSettings,
    DeviceAuditLog,
    DeviceStateSummary,
    DeviceSystemSettings,
    ExpertView,
    SimpleAlert,
    LoadingSpinner
  },

  setup() {
    const { t } = useI18n();
    const refresh = ref(false);

    const store = useStore();
    const device = computed(() => store.state.device.current);
    const config = ref(device.value.configV2);

    const loading = computed(() => store.state.device.loading)


    const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));
    const isSuperAdmin = computed(() => {
      return store.getters["auth/isSuperAdmin"];
    });
    const confirmOpen = ref(false);
    const confirmRedirect = ref(false);
    const openExpertView = ref(false);
    const confirmLooseConfig = ref(false);

    const newConfig = ref({});
    const formValid = ref({
      bracelets: true,
      dailyCheckIn: true
    });

    const enableSave = computed(() => {

      if (JSON.stringify(newConfig.value) != JSON.stringify(config.value) && Object.values(formValid.value).every(val => val)) {
        return true
      } else {
        return false
      }
    })

    let refTo;

    const serialNumber = store.state.device.current.serialNumber;

    watch(device, () => {
      if (device.value) {
        newConfig.value = {};
        config.value = device.value.configV2;
      }
    }, { deep: true });


    const getTemporaryConfig = () => {
      let tmpConfig;
      if (newConfig.value && Object.keys(newConfig.value).length === 0) {
        tmpConfig = JSON.parse(JSON.stringify(device.value.configV2));
      } else {
        tmpConfig = newConfig.value;
      }
      return tmpConfig;
    };

    const onUpdateSystemConfig = (config) => {
      let tmpConfig = getTemporaryConfig();
      tmpConfig.system = config;
      newConfig.value = tmpConfig;
    };

    const onUpdateSecurityConfig = (config) => {
      let tmpConfig = getTemporaryConfig();

      if (config.alarmConfig) {
        tmpConfig.alarm_app.destinations[config.alarmConfig.index].scaip.controller_id = config.alarmConfig.value;
      }

      if (config.bracelets) {

        tmpConfig.accessories.bracelets = config.bracelets;

        if (config.bracelets.filter((bracelet) => !bracelet.valid).length) {
          formValid.value.bracelets = false
        } else {
          formValid.value.bracelets = true
        }
        if (config.bracelets.length == 0) {
          formValid.value.bracelets = true
        }
      }

      if (config.alarmTriggers) {

        tmpConfig.alarm_app.triggers.voice = config.alarmTriggers.triggeredByVoice;
        tmpConfig.alarm_app.triggers.touch = config.alarmTriggers.triggeredByTouch;
        tmpConfig.alarm_app.triggers.fall = config.alarmTriggers.triggeredByBracelet;
        tmpConfig.system.low_power_mode.enabled = !config.alarmTriggers.triggeredByVoiceOnBattery;
      }

      if (config.dailyCheckIn) {
        tmpConfig.daily_check_in_app.enabled = config.dailyCheckIn.enabled;
        tmpConfig.daily_check_in_app.suspended = config.dailyCheckIn.suspended;
        tmpConfig.daily_check_in_app.suspension_end = config.dailyCheckIn.suspensionEnd;
        tmpConfig.daily_check_in_app.grace_period = config.dailyCheckIn.gracePeriod;
        tmpConfig.daily_check_in_app.start = config.dailyCheckIn.checkTime;
        tmpConfig.daily_check_in_app.reminders = config.dailyCheckIn.reminders;

        formValid.value.dailyCheckIn = config.dailyCheckIn.valid
      }

      newConfig.value = tmpConfig;
    };

    const onUpdateHealthConfig = (config) => {
      let tmpConfig = getTemporaryConfig();

      if (config.co2Config) {
        tmpConfig.co2_app.enabled = config.co2Config;
      }

      if (config.hydrationConfig) {
        tmpConfig.hydration_reminder_app.enabled = config.hydrationConfig.enabled;
        tmpConfig.hydration_reminder_app.interval = config.hydrationConfig.interval;
        tmpConfig.hydration_reminder_app.timings = config.hydrationConfig.timings;
        tmpConfig.hydration_reminder_app.periods = config.hydrationConfig.periods;
        tmpConfig.hydration_reminder_app.audio = config.hydrationConfig.audio;
      }

      newConfig.value = tmpConfig;
    };

    const hasVersionSupport = () => {
      if (device.value && device.value.firmware && device.value.firmware.version) {
        let splitFirmware = device.value.firmware.version.split(".");

        if (parseInt(splitFirmware[0]) < 2023) return false;
        if (parseInt(splitFirmware[0]) > 2023) return true;
        if (parseInt(splitFirmware[1]) >= 3 && parseInt(splitFirmware[2]) >= 3) return true;
      }
      return false;
    };

    const cancel = async () => {
      await store.dispatch("device/reloadDevice", serialNumber);
      refresh.value = true;
      await nextTick();
      refresh.value = false;
      config.value = device.value.configV2;
    };

    const save = async () => {
      confirmOpen.value = false;

      checkDeviceId()

      await Store.dispatch("device/updateConfig", {
        id: device.value.id,
        data: newConfig.value,
      });

      await store.dispatch("device/reloadDevice", serialNumber);

      refresh.value = true;
      await nextTick();
      refresh.value = false;

      config.value = device.value.configV2;

      store.dispatch("showNotification", {
        title: t("notifications.mutations.success"),
        text: t("notifications.mutations.delivering"),
        type: "info",
      });

      newConfig.value = {};
    };

    const checkDeviceId = () => {

      if (config.value && config.value.alarm_app.destinations && newConfig.value.alarm_app.destinations) {
        for (let i = 0; i < config.value.alarm_app.destinations.length; i++) {
          // controller_id changed

          if (config.value.alarm_app.destinations[i].scaip && config.value.alarm_app.destinations[i].scaip.controller_id !== newConfig.value.alarm_app.destinations[i].scaip.controller_id) {
            let tmpConfig = getTemporaryConfig();

            const oldControllerId = config.value.alarm_app.destinations[i].scaip.controller_id;
            const newControllerId = newConfig.value.alarm_app.destinations[i].scaip.controller_id;

            const sender = newConfig.value.alarm_app.destinations[i].scaip.sender;
            const callerId = newConfig.value.alarm_app.destinations[i].scaip.caller_id;
            const caller = newConfig.value.alarm_app.destinations[i].call.caller;

            const senderUsername = sender.substring(sender.indexOf("sip:") + "sip:".length, sender.indexOf("@"));
            const senderAuthUser = sender.substring(sender.indexOf("auth_user=") + "auth_user=".length)

            const subCallerId = callerId.substring(callerId.indexOf("sip:") + "sip:".length, callerId.indexOf("@"));

            const callerUsername = caller.substring(sender.indexOf("sip:") + "sip:".length, caller.indexOf("@"));
            const callerAuthUser = caller.substring(sender.indexOf("auth_user=") + "auth_user=".length)


            if (senderUsername.includes(oldControllerId)) {
              tmpConfig.alarm_app.destinations[i].scaip.sender = checkUserInfo(senderUsername, sender, oldControllerId, newControllerId);
            }

            if (oldControllerId.length == 0) {
              const pos = tmpConfig.alarm_app.destinations[i].scaip.sender.indexOf("auth_user=") + "auth_user=".length;
              const nextChar = tmpConfig.alarm_app.destinations[i].scaip.sender.substring(pos, pos + 1)
              if (nextChar == ";" || nextChar == '')
                tmpConfig.alarm_app.destinations[i].scaip.sender = tmpConfig.alarm_app.destinations[i].scaip.sender.substring(0, pos) + "" + newControllerId + "" + tmpConfig.alarm_app.destinations[i].scaip.sender.substring(pos);
            } else if (oldControllerId.length > 0 && senderAuthUser.startsWith(oldControllerId)) {
              tmpConfig.alarm_app.destinations[i].scaip.sender = tmpConfig.alarm_app.destinations[i].scaip.sender.replace("auth_user=" + oldControllerId, "auth_user=" + newControllerId);
            }

            if (subCallerId.includes(oldControllerId)) {
              tmpConfig.alarm_app.destinations[i].scaip.caller_id = checkUserInfo(subCallerId, callerId, oldControllerId, newControllerId)
            }

            if (callerUsername.includes(oldControllerId)) {
              tmpConfig.alarm_app.destinations[i].call.caller = checkUserInfo(callerUsername, caller, oldControllerId, newControllerId);
            }

            if (oldControllerId.length == 0) {
              const pos = tmpConfig.alarm_app.destinations[i].call.caller.indexOf("auth_user=") + "auth_user=".length;
              const nextChar = tmpConfig.alarm_app.destinations[i].call.caller.substring(pos, pos + 1)
              if (nextChar == ";" || nextChar == '')
                tmpConfig.alarm_app.destinations[i].call.caller = tmpConfig.alarm_app.destinations[i].call.caller.substring(0, pos) + "" + newControllerId + "" + tmpConfig.alarm_app.destinations[i].call.caller.substring(pos);
            } else if (oldControllerId.length > 0 && callerAuthUser.startsWith(oldControllerId)) {
              tmpConfig.alarm_app.destinations[i].call.caller = tmpConfig.alarm_app.destinations[i].call.caller.replace("auth_user=" + oldControllerId, "auth_user=" + newControllerId);
            }
            newConfig.value = tmpConfig;
          }
        }
      }
    }

    // https://www.rfc-editor.org/rfc/rfc3986.html#page-25
    const checkUserInfo = (userInfo, fullData, oldControllerId, newControllerId) => {
      if (oldControllerId.length == 0) {
        return fullData.replace("sip:@", "sip:" + newControllerId + "@");
        // case sip:[userinfo]@
      } else if (userInfo == oldControllerId) {
        return fullData.replace("sip:" + oldControllerId + "@", "sip:" + newControllerId + "@");
        // case sip:[userinfo]:pwd@
      } else if (userInfo.includes(":")) {
        const split = userInfo.split(":")
        if (split[0] == oldControllerId) {
          return fullData.replace("sip:" + oldControllerId + ":" + split[1] + "@", "sip:" + newControllerId + ":" + split[1] + "@");
        }
      }
      return fullData;
    }

    const saveJsonConfig = async (givenConfig) => {
      let response = await Store.dispatch("device/updateConfig", {
        id: device.value.id,
        data: JSON.parse(givenConfig),
      });

      if (response) {
        newConfig.value = {};
        openExpertView.value = false;

        await store.dispatch("device/reloadDevice", serialNumber);

        refresh.value = true;
        await nextTick();
        refresh.value = false;

        config.value = device.value.configV2;

        store.dispatch("showNotification", {
          title: t("notifications.mutations.success"),
          text: t("notifications.mutations.delivering"),
          type: "info",
        });
      } else {
        // TODO return correct error and show error
        store.dispatch("showNotification", {
          title: t("notifications.mutations.error"),
          text: t("notifications.mutations.jsonParse"),
          type: "error",
        });
      }
    };

    onMounted(() => {
      initAccordions();
    });

    onBeforeRouteLeave((to, from, next) => {
      if (JSON.stringify(config.value) == JSON.stringify(newConfig.value)) {
        next();
      } else if (!hasVersionSupport()) {
        next();
      } else {
        refTo = next;
        confirmRedirect.value = true;
      }
    });

    const leave = async () => {
      confirmRedirect.value = false;
      refTo();
    }

    const openExpertViewHandler = () => {
      if (JSON.stringify(config.value) == JSON.stringify(newConfig.value)) {
        openExpertView.value = true;
      } else {
        confirmLooseConfig.value = true;
      }
    }

    const confirmLooseConfigHandler = async () => {
      config.value = device.value.configV2;
      refresh.value = true;
      await nextTick();
      refresh.value = false;
      openExpertView.value = true;
      confirmLooseConfig.value = false;
    }

    return {
      t,
      loading,
      device,
      signalTicks,
      isEditAllowed,
      batteryTicks,
      connectionTicks,
      confirmOpen,
      openExpertView,
      dateFormat(date) {
        return new Date(date).toLocaleString("de-DE");
      },
      onUpdateSystemConfig,
      onUpdateSecurityConfig,
      onUpdateHealthConfig,
      save,
      cancel,
      saveJsonConfig,
      hasVersionSupport,
      leave,
      confirmRedirect,
      isSuperAdmin,
      formValid,
      enableSave,
      openExpertViewHandler,
      refresh,
      confirmLooseConfig,
      confirmLooseConfigHandler
    };
  },
};
</script>
<style>
.force-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.force-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>

<template>
    <div data-accordion="open">
        <h2 id="accordion-open-heading-3">
            <button type="button" class="flex items-center justify-between w-full px-4 py-2 font-medium text-left text-black !bg-gray-100 border border-gray-200 rounded-t-xl" data-accordion-target="#accordion-open-body-3" aria-expanded="true" aria-controls="accordion-open-body-3">
                <span class="flex items-center">{{ $t("health.header.health") }}</span>
                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h2>
        <div id="accordion-open-body-3" class="hidden" aria-labelledby="accordion-open-heading-3">
            <div class="p-5 pb-0 border border-gray-200">
                <div class="mb-2">
                    <DeviceCo2Setting @updateConfig="onUpdateCo2Settings" :refresh="props.refresh" />
                    <DeviceHydrationReminder @updateConfig="onUpdateHydrationSettings" :refresh="props.refresh" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import DeviceCo2Setting from "@/components/DeviceCo2Setting";
import DeviceHydrationReminder from "@/components/DeviceHydrationReminder";
import { defineEmits, defineProps } from "vue";

const emit = defineEmits(["updateConfig"]);
const props = defineProps(["refresh"])

const onUpdateCo2Settings = (enabled) => {
    emit("updateConfig", { co2Config: enabled });
};

const onUpdateHydrationSettings = (hydrationConfig) => {
    emit("updateConfig", { hydrationConfig: hydrationConfig });
};
</script>

import { getAlertLevel, hasAlert } from "./alarms";

const alarmStates = {
  unknown: ["text-gray-800 bg-gray-200"],
  normal: ["text-gray-800 bg-green-300 bg-opacity-50"],
  warning: ["text-gray-800 bg-yellow-100 bg-opacity-75"],
  minor: ["text-gray-800 bg-orange-300 bg-opacity-50"],
  major: ["text-gray-800 bg-red-400 bg-opacity-60"],
  critical: ["text-gray-800 bg-red-400"],
};

const statusOffline = ["bg-gray-100", "text-gray-800"];
const statusOk = ["bg-green-100", "text-green-800"];
const statusWarning = ["bg-yellow-100", "text-yellow-800"];
const statusError = ["bg-red-100", "text-red-800"];

const overallBraceletStatusColor = (device) => {
  if (!device.isOnline) {
    return statusOffline;
  }
  return statusOffline;
};

const deviceIdentityStatusColor = (device) => {
  if (device.state == "ACTIVE") {
    return statusOk;
  } else {
    return statusOffline;
  }
};

const onlineStatusColor = (device, alert = "") => {
  if (!device) return statusError;

  if (alert != "" && hasAlert(alert, device)) {
    let severity = getAlertLevel(alert, device);
    return alarmStates[severity];
  }

  if (device.isOnline) {
    return statusOk;
  }
  if (device.stateSummary.deviceReportedDevicePresence === "offline_battery_empty") {
    return statusOffline;
  }
  if (device.stateSummary.deviceReportedDevicePresence === "offline_low_power_mode") {
    return statusOk;
  }
  if (device.stateSummary.deviceReportedDevicePresence === "offline_reboot") {
    return statusOk;
  }
  if (device.stateSummary.deviceReportedDevicePresence === "offline_turned_off") {
    return statusOffline;
  }
  return statusError;
};

const wifiStatusColor = (device, alert = "") => {
  if (alert != "" && hasAlert(alert, device)) {
    let severity = getAlertLevel(alert, device);
    return alarmStates[severity];
  }

  if (!device.isOnline || !device.telemetry.wifi) {
    return statusOffline;
  }
  if (device.telemetry.wifi.signalQuality === "GOOD") {
    return statusOk;
  }
  if (device.telemetry.wifi.signalQuality === "SUFFICIENT") {
    return statusWarning;
  }
  return statusError;
};

const modemStatusColor = (device) => {
  if (!device.isOnline || !device.telemetry.modem) {
    return statusOffline;
  }
  if (device.telemetry.modem.isConnected) {
    return statusOk;
  }
  return statusError;
};

const batteryStatusColor = (device) => {
  if (!device.isOnline || !device.telemetry.power) {
    return statusOffline;
  }

  const state = device.telemetry.power.batteryState;

  if (state === "FULLY_CHARGED") {
    return statusOk;
  }

  if (state === "RECHARGING" || state === "DRAINING") {
    return statusWarning;
  }
  return statusError;
};

const dailyCheckInStatusColor = (device) => {
  if (!device.isOnline) {
    return statusOffline;
  }
  if (device.stateSummary.dailyCheckIn === "active") {
    return statusOk;
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_device") {
    return statusWarning;
  }
  if (device.stateSummary.dailyCheckIn === "paused_by_platform") {
    return statusWarning;
  }
  if (device.stateSummary.dailyCheckIn === "missing") {
    return statusError;
  }
  return statusOffline;
};

const powerSupplyStatusColor = (device, alert = "") => {
  if (alert != "" && hasAlert(alert, device)) {
    let severity = getAlertLevel(alert, device);
    return alarmStates[severity];
  }

  if (!device.isOnline) {
    return statusOffline;
  }
  if (device.stateSummary.powerSupply === "connected") {
    return statusOk;
  }
  if (device.stateSummary.powerSupply === "disconnected") {
    return statusError;
  }
  return statusOffline;
};

const cloudPresenceStatusColor = (device) => {
  if (["online"].indexOf(device.stateSummary.cloudReportedDevicePresence) >= 0) {
    return statusOk;
  } else if (
    ["offline_cloud_initiated", "offline_device_initiated", "offline_unknown"].indexOf(
      device.stateSummary.cloudReportedDevicePresence
    ) >= 0
  ) {
    return statusError;
  }
  return statusOffline;
};

const devicePresenceStatusColor = (device) => {
  if (["online"].indexOf(device.stateSummary.deviceReportedDevicePresence) >= 0) {
    return statusOk;
  } else if (
    ["offline_battery_empty", "offline_low_power_mode", "offline_turned_off", "offline_reboot"].indexOf(
      device.stateSummary.deviceReportedDevicePresence
    ) >= 0
  ) {
    return statusError;
  }
  return statusOffline;
};

const braceletPresenceStatusColor = (device, alert = "") => {
  if (!device.isOnline) {
    return statusOffline;
  }

  if (alert != "" && hasAlert(alert, device)) {
    let severity = getAlertLevel(alert, device);
    return alarmStates[severity];
  }

  if (device.stateSummary.braceletBattery === "critical") {
    return statusError;
  }
  if (device.stateSummary.braceletPresence === "in_range") {
    return statusOk;
  }
  if (device.stateSummary.braceletPresence === "out_of_range") {
    return statusError;
  }
  return statusOffline;
};

const braceletBatteryStatusColor = (device) => {
  if (device.bracelets.length) {
    if (["ok"].indexOf(device.stateSummary.braceletBattery) >= 0) {
      return statusOk;
    } else {
      if (["critical"].indexOf(device.stateSummary.braceletBattery) >= 0) {
        return statusError;
      }
    }
  }
  return statusOffline;
};

export {
  overallBraceletStatusColor,
  wifiStatusColor,
  modemStatusColor,
  batteryStatusColor,
  onlineStatusColor,
  deviceIdentityStatusColor,
  cloudPresenceStatusColor,
  devicePresenceStatusColor,
  braceletPresenceStatusColor,
  braceletBatteryStatusColor,
  powerSupplyStatusColor,
  dailyCheckInStatusColor,
};

<template>
    <div id="accordion-open" data-accordion="open">
        <h2 id="accordion-open-heading-1">
            <button type="button" class="flex items-center justify-between w-full px-4 py-2 font-medium text-left text-black !bg-gray-100 border border-gray-200 rounded-t-xl" data-accordion-target="#accordion-open-body-1" aria-expanded="true" aria-controls="accordion-open-body-1">
                <span class="flex items-center">{{ $t("system.header.system") }}</span>
                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h2>
        <div id="accordion-open-body-1" class="hidden" aria-labelledby="accordion-open-heading-1 ">
            <div class="p-5 pb-3 border border-gray-200">
                <div class="mb-8">
                    <h1 class="mb-2 font-medium text-left text-gray-900">{{ $t("system.header.volumeSettings") }}</h1>
                    <div class="flex">
                        <div v-for="tab in volumeSettings" :key="tab.id" class="h-10 mr-4">
                            <div :class="[
                                volume === tab.volumeValue ? 'border-indigo-500' : 'border-text-gray-900',
                                isEditAllowed ? 'hover:border-text-gray-700 cursor-pointer' : '',
                                'py-2 flex flex-col items-center',
                            ]" @click="changeVolume(tab.volumeValue)">
                                <component :is="tab.icon" :class="[
                                    volume === tab.volumeValue ? 'text-indigo-500' : 'text-gray-900',
                                    isEditAllowed ? 'hover:text-gray-700' : '',
                                ]" aria-hidden="true" />

                                <span :class="[
                                    volume === tab.volumeValue ? 'text-indigo-500' : 'text-gray-900',
                                    isEditAllowed ? 'hover:text-gray-700' : '',
                                    'mt-2 ',
                                ]">
                                    {{ $t(tab.name) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { hasDeviceTenantPermission, USER_PERMISSIONS } from "@/lib/userHasPermission";
import { VolumeQuietIcon, VolumeDefaultIcon, VolumeLoudIcon } from "@/components/icons";

export default {
    name: "DeviceSystemSettings",
    components: {},

    props: ["refresh"],
    emits: ["updateConfig"],

    setup(props, { emit }) {

        const isEditAllowed = computed(() => hasDeviceTenantPermission(USER_PERMISSIONS.ADMIN));

        const store = useStore();
        const device = computed(() => store.state.device.current);
        const volume = ref(device.value.configV2.system.audio.volume);

        watch(device, () => {
            if (device.value)
                volume.value = device.value.configV2.system.audio.volume
        })

        watch(() => props.refresh, () => {
            if (props.refresh) volume.value = device.value.configV2.system.audio.volume;
        })

        let volumeSettings = [
            {
                volumeValue: 70,
                name: "system.volume.quiet",
                icon: VolumeQuietIcon,
            },
            {
                volumeValue: 85,
                name: "system.volume.default",
                icon: VolumeDefaultIcon,
            },
            {
                volumeValue: 100,
                name: "system.volume.loud",
                icon: VolumeLoudIcon,
            },
        ];

        const changeVolume = async (volumeValue) => {
            if (isEditAllowed.value && volumeValue !== volume.value) {
                volume.value = volumeValue;

                const tmpConfig = JSON.parse(JSON.stringify(device.value.configV2.system));
                tmpConfig.audio.volume = volumeValue
                emit("updateConfig", tmpConfig);
            }
        };

        return {
            volume,
            volumeSettings,
            isEditAllowed,
            changeVolume,
        };
    },
};
</script>
